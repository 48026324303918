const openSubLabels = (el, id) => {
	const target = document.getElementById(id)
	const isChecked = el.checked

	isChecked ? target.classList.remove('hidden') : target.classList.add('hidden') 
}

const openSubLabelsRadio = (name) => {
	const radios = document.querySelectorAll(`input[name="${name}"]`)
	radios.forEach(r => {
		r.addEventListener('change', () => {
			const sublabel = document.querySelectorAll(`input[name="${name}"] ~ .sublabels`)
			sublabel.forEach(l => {
				l.classList.add('hidden')
			})
			r.checked && r.parentElement.querySelector('.sublabels').classList.remove('hidden')
		})
	})
}

const validateForm = (id, el, msg) => {
	const form = document.getElementById(id)
	const element = document.getElementById(el)
	const msgContainer = document.querySelector(`[data-validate="${el}"]`)
	const msgCContent = document.querySelector(`[data-validate="${el}"] span`)
	form.addEventListener('submit', e => {
		e.preventDefault();
		if (element.checked) {
			msgCContent.innerHTML = ""
			msgContainer.classList.remove('err')
			form.submit()
		} else {
			msgCContent.innerHTML = `${msg}`
			msgContainer.classList.add('err')
			return;
		}
	})
}
 
const selectReminders = el => {
	const fieldsets = document.querySelectorAll('.side--grid fieldset')
	const activeFieldset = document.getElementById(el)
	fieldsets.forEach(f => {
		f.classList.remove('active')
	})
	console.log(activeFieldset);
	activeFieldset.classList.add('active')

}

if (document.querySelector('.sublabels')) {
	openSubLabelsRadio('lieferadresse')
	validateForm('order--form', 'consent', ' (Pflichtfeld)')
}

if (document.getElementById('kontaktlinsen')) {
	document.getElementById('kontaktlinsen').addEventListener('change', e => {
		openSubLabels(e.target, 'kontaktlinsen-sublabels')		
	})
}

